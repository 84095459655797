import {useState, useEffect} from 'react';
import React from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

const RepetitionSelector = (props) => {

/* Reps*/
const [radioValue, setRadioValue] = useState(props.selectedRep);

const componentName = props.componentName;

const radios = [
  { name: '1', value: 1},
  { name: '2', value: 2 },
  { name: '3', value: 3 },
  { name: '4', value: 4 },
  { name: '5', value: 5 },
  { name: '6', value: 6 },
  { name: '7', value: 7 },
  { name: '8', value: 8 },
  { name: '9', value: 9 },
  { name: '10', value: 10 },
  { name: '11', value: 11 },
  { name: '12', value: 12 },
  /*{ name: '13', value: 13 },
  { name: '14', value: 14 },
  { name: '15', value: 15 },
  { name: '16', value: 16 },
  { name: '17', value: 17 },
  { name: '18', value: 18 },
  { name: '19', value: 19 },
  { name: '20', value: 20 },*/
];

useEffect(()=>{
    setRadioValue(props.selectedRep);
},[props.selectedRep]); 

function handleChange(event){
  setRadioValue(parseInt(event.currentTarget.value))
  //console.log("clicked Radio " + parseInt(event.currentTarget.value));
  props.onChange(parseInt(event.currentTarget.value));   
}
  
  return (<ButtonGroup>
            {radios.map((radio, idx) => (
                <ToggleButton   size="sm"
                                key={idx}
                                id={componentName+`-${idx}`}
                                type="radio"
                                variant="outline-dark"
                                name={componentName}
                                value={radio.value}
                                checked={radioValue === radio.value}
                                //onChange={(e) => {setRadioValue(parseInt(e.currentTarget.value));console.log('change ' + radioValue);}}
                                onChange={(e) =>{handleChange(e) }}>
                    {radio.name}
                </ToggleButton>
    ))}
  </ButtonGroup>

)
   }

  export default RepetitionSelector;