import { useState, useEffect, useContext } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import classes from './Training.module.css';
import TrainingsUserList from '../components/widgets/TrainingsUserList';
import TrainingsplanList from '../components/widgets/TrainingsplanList';
import UserAndPlanContext from '../store/UserAndPlanContext';
import LoadingSpinner from '../components/widgets/LoadingSpinner';
import Table from 'react-bootstrap/Table';
import {format,  formatDistance,differenceInMinutes} from 'date-fns';

function TrainingPage(props) {
  const [isLoading, setIsLoading] = useState(false);
  const userAndPlanCtx = useContext(UserAndPlanContext);
  
  const [trainingsList, setTrainingsList] = useState([]);
  
  //const currentUserIdToRetrieve = userAndPlanCtx.user.slice(-1).map((user) => user.id);
  //const currentPlanIdToRetrieve = userAndPlanCtx.plan.slice(-1).map((plan) => plan.id);
  const currentUserIdToRetrieve = userAndPlanCtx.user;
  const currentPlanIdToRetrieve = userAndPlanCtx.plan;
  console.log("CTX current User:", currentUserIdToRetrieve);
  console.log("CTX current Plan:", currentPlanIdToRetrieve);

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [error, setError] = useState(null);


  function startTrainingHandler(anyUnknownData){
    console.log("wir merken uns den gewählten User + TP ");
    localStorage.setItem("pumpDB.user", JSON.stringify(currentUserIdToRetrieve));
    localStorage.setItem("pumpDB.plan", JSON.stringify(currentPlanIdToRetrieve));
  }

  // Funktion zum Abrufen der Geolokation
  const fetchGeolocation = () => {
    console.log('fetchGeolocation');
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);

          // Überprüfen, ob beide Werte nicht null sind
          if (latitude !== null && longitude !== null) {
            // Speichere die Koordinaten im Local Storage
            localStorage.setItem("pumpDB.latitude", latitude);
            localStorage.setItem("pumpDB.longitude", longitude);
          }
          else
          {
            localStorage.setItem("pumpDB.latitude", JSON.stringify("51.2"));
            localStorage.setItem("pumpDB.longitude", JSON.stringify("13.2"));     
          }
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };
  
  useEffect(() => {
    document.title = 'pumpDB 3.0 - (sw) [2023]';
    setIsLoading(true);
    
    fetchGeolocation();

    fetch(
      process.env.REACT_APP_API_HOST+'/training',
      {
        method: 'GET',
        headers: {'Authorization': 'Basic '+btoa(process.env.REACT_APP_BASIC_AUTH),
                  'Access-Control-Request-Headers': 'Authorization',
                   //'Content-Type': 'application/json',
                  'Content-Type': 'application/x-www-form-urlencoded'
                 }
      })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        const trainings = [];
        setTrainingsList(trainings);

        for (const key in data) {
          const tmpTraining = {
            id: key,
            ...data[key]
          };
          
          trainings.push(tmpTraining);
        }
        setTrainingsList(trainings);  
        setIsLoading(false);    
        console.log("lese Training neu aus " + trainingsList.length);     
        
      })
      .catch(error => {console.log(error);  throw(error);} );
    
    }, []);

  if (isLoading) {
    return (<LoadingSpinner />);
  }

  return <><section className={userAndPlanCtx.user.length !== 0 && userAndPlanCtx.plan.length !== 0 ? classes.startTraining : classes.hideStartTraining}>
            <h1>lets lift</h1>
              <Link to="/workout" onClick={startTrainingHandler}>
                los gehts
              </Link>
          </section>
          <section>
              <h1>who are you today?</h1>
              <TrainingsUserList />
          </section>
          <section>
              <h1>what is your plan?</h1>
              <TrainingsplanList />
            </section>
          <section>
            <h1>last trainings sessions</h1>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>Duration</th>
                  <th>User</th>
                </tr>
              </thead>
              <tbody>
              {trainingsList.slice(-10).map((training,index) => {return <tr key={index}>
                                                                        <td>{training.id}</td>
                                                                        <td>{training.start}</td>
                                                                        <td>{training.finish}</td>
                                                                        <td>{differenceInMinutes(new Date(training.finish), (new Date(training.start)))}</td>
                                                                        <td>{training.user}</td>
                                                                      </tr>})}
                </tbody>
              </Table> 
          </section>
          </>
}

export default TrainingPage;