import * as React from "react";
import CardPP from "../components/ui/CardWithHeaderAndFooter";
import {format,  formatDistance} from 'date-fns';

const buildTime = new Date('2025-01-11 18:10');
const buildVersion = "2.0.501.";

const DUMMY_DATA = [
    {
      id: '1',
      title: 'Layout und Funktionsumfang',
      status: 'in progress'
    },
    {
      id: '2',
      title: 'User als Carousel anzeigen',
      status: 'done'
    },
    {
      id: '3',
      title: 'User/Plan mit Button für die Auswahl machen -> Context stored in Loacl',
      status: 'done'
    },
    {
      id: '4',
      title: 'training starten (LocalStorage), Url = workout/[id des trainings]',
      status: 'in progress'
    },
    {
      id: '5',
      title: 'TrainingGateway -> POST Request mit USer -> liefert aktuelles Training (CHECK) oder legt es an´(CHECK)',
      status: 'done'
    },
    {
      id: '6',
      title: 'TrainingsUserList aus der UserList von Array zu String und die folgendne Aufrufe (Carusell ...) anpassen.   const [activeTrainingsUser, setActiveTrainingsUser] = useState([]);',
      status: 'done'
    },
    {
      id: '7',
      title: 'SELECT tp.name tpName,e.name exerciseName,tpi.* FROM pumpdb.trainingsplan tp  JOIN pumpdb.trainingsplan_items tpi on tpi.tp_id = tp.id       JOIN pumpdb.exercise e on e.ext_id = tpi.ext_id       where tp.id = 9       and not exists (SELECT 1 from pumpdb.trainingsplan_workout tpo               join pumpdb.workout wo on wo.id = tpo.wo_id                       where (tpo.tp_item_id = tpi.id and wo.training = 485));',
      status: 'done'
    },
    {
      id: '8',
      title: 'Componenten für die App wählen und anordnen',
      status: 'in progress'
    },
    {
      id: '9',
      title: 'TrainingsController: ABfrage aller Workouts des Trainings',
      status: 'done'
    },
    {
      id: '10',
      title: 'TPI pump Item fertig bauen, Löst augenscheinlich das Reload Problem, Aktuell wird nur der TPI nicht neu gelesen',
      status: 'done'
    },
    {
      id: '11',
      title: 'weight inuput prüfen, erst gewicht dann rep führ dazu das mehrfach ein TPI geinserted werden kann',
      status: 'done'
    },
    {
      id: '12',
      title: 'History-Componente an PumpForm andocken',
      status: 'done'
    },
    {
      id: '13',
      title: 'NavBar einbauen',
      status: 'done'
    },
    {
      id: '14',
      title: 'ExerciseListe einbauen',
      status: 'done'
    },
    {
      id: '15',
      title: 'Layoutthemen: TP- Text zu groß für Carusell, SELECT: Text zu groß, Beide: Resetknopf weg, Repetitions verstecken, Weight Feld kleiner, HEADER: anders anordnen (Platzsparender), HISTORY: kleineres graues  Feld (1 Zeile)',
      status: 'in progress'
    },
    {
      id: '16',
      title: 'History bei aktiven Training jede Minute aktualisieren',
      status: 'open'
    },
    {
      id: '17',
      title: 'Trainingseite markiert gewählten Plan und User und zeig ggf. ID und Startzeit des aktuellen Trainings an.',
      status: 'done'
    },
    {
      id: '18',
      title: 'Exceptionhandling in Fetch so wie bei History, Überlegen wo die Fehler am besten Stehen. Global oder immer am Element',
      status: 'open'
    },
    {
      id: '19',
      title: 'WorkoutExerciseRanking soll immer nur die 1 Liste anzeigen, die zurück kommt',
      status: 'done'
    },
    {
      id: '20',
      title: 'Training die Duration berechnen und auf Trainingseite mit anzeigen',
      status: 'done'
    },
    {
      id: '21',
      title: 'Ohne gewähltes Training darf die Workoutseite nicht starten -> Fehler wg. Zeitangaben-> speichern des Trainigs in Local Storage und beim Stop des Training muss der Storage gelöscht werden',
      status: 'open'
    }

  ];

function DevBoardPage() {
    const uuid = JSON.parse(localStorage.getItem("user")) || null;
    return <section>
           <h1>Dev Board - Next Steps</h1>
           <h3>build: {buildVersion+format(buildTime, 'yyyyMMdd')}</h3>
           {
             <CardPP header="next big thing" 
                     title=".-." 
                     content={<ul>
                     {DUMMY_DATA.map((meetup) => {
                         return meetup.status !== 'done' ? <li key={meetup.id}>{meetup.title}</li> : '' ;
                     })}
                     </ul>} 
                     footer={formatDistance(buildTime, new Date(), { addSuffix: true })}/>
           }
           </section>
           
}

export default DevBoardPage;