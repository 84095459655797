import React, { useState, useContext } from 'react';
import UserAndPlanContext from '../../store/UserAndPlanContext';
import RepetitionSelector from '../widgets/RepetitionSelector';
import WeightInput from '../widgets/WeightInput';
import WorkoutTPIComponent from '../widgets/WorkoutTPIComponent';
import WorkoutSelectComponent from '../widgets/WorkoutSelectComponent';

function WorkoutPumpForm(props) {
  const [currentTraining, setCurrentTraining] = useState(props.trainingID);
  const [queryWTPIData, setQueryWTPIData] = useState(1);
  const [newWorkout, setNewWorkout] = useState([]);

  const displayMode = props.displayMode;

  const currentUserIdToRetrieve = JSON.parse(localStorage.getItem("pumpDB.user"));
  const currentPlanIdToRetrieve = JSON.parse(localStorage.getItem("pumpDB.plan"));

  const [selectedRep, setSelectedRep] = useState(10);
  const [selectedWeight, setSelectedWeight] = useState(0);
  const [selectedExercise, setSelectedExercise] = useState([]);
  const [selectedExerciseName, setSelectedExerciseName] = useState([]);
  const [selectedTPI, setSelectedTPI] = useState(0);

  const latitude = JSON.parse(localStorage.getItem("pumpDB.latitude"));
  const longitude = JSON.parse(localStorage.getItem("pumpDB.longitude"));

  function changeExerciseHandler(selectedExerciseData)
  {
    if(selectedExerciseData)
    {
      console.log("Neue Exercise von unten",selectedExerciseData.ext_id);
      setSelectedRep(selectedExerciseData.def_repetitions);
      setSelectedWeight(parseInt(selectedExerciseData.def_weight));
      setSelectedExercise(selectedExerciseData.ext_id);
      setSelectedExerciseName(selectedExerciseData.name);
    }
    
  }
  
  function changeWTPIHandler(selectedWTPIData){
    if(selectedWTPIData)
    {
      setSelectedRep(selectedWTPIData.repetition);
      setSelectedWeight(selectedWTPIData.weight)
      setSelectedExercise(selectedWTPIData.ext_id);
      setSelectedExerciseName(selectedWTPIData.name);
      setSelectedTPI(selectedWTPIData.id);
    }
  }
  
  function changeWeightHandler(changedWeightData){
    console.log("bekomme von unten: " + changedWeightData);
    setSelectedWeight(changedWeightData)
  }

  function changeRepetitionHandler(changeRepetitionData){
    console.log("bekomme von unten: " + changeRepetitionData);
    setSelectedRep(changeRepetitionData)
  }

  function pushWeightHandler(pushedWeightData){
    console.log("DB-Insert + Reload WTPIData");
    console.log(selectedTPI+'|'+selectedRep+'|'+selectedWeight);

    const addWorkout = 
    {
      training : currentTraining,
      repetition : selectedRep,
      exercise : selectedExercise,
      weight : selectedWeight,//pushedWeightData,
      latitude : latitude,
      longitude : longitude,
      tp_item : selectedTPI ?? null
    };

    fetch(
      process.env.REACT_APP_API_HOST+'/workout',
      {
        method: 'POST',
        body: JSON.stringify(addWorkout),
        headers: {'Authorization': 'Basic '+btoa(process.env.REACT_APP_BASIC_AUTH),
                  'Access-Control-Request-Headers': 'Authorization',
                   //'Content-Type': 'application/json',
                  'Content-Type': 'application/x-www-form-urlencoded'
                 }
      })
      .then((response) => {
        return response.json();
      })
      .then((data) => {

        for (const key in data) {
          const w = {
            id: key,
            ...data[key]
          };
          
          //neu zusammenstückeln, da der Workoutname nicht mit zurück kommt!
          const storedWorkout = 
          {
            exercise: w.exercise,
            id: w.id,
            latitude: w.latitude,
            longitude: w.longitude,
            repetition: w.repetition,
            training: w.training,
            weight: w.weight,
            date: w.date,
            name: selectedExerciseName,
            tp_item: selectedTPI ?? null
          };        
          console.log('Return of workout add: '+storedWorkout.id)
          //console.log("ExerciseName: " + selectedExerciseName);
          setNewWorkout(storedWorkout); // wird in useEffect noch nicht geschrieben, deswegen übergeben wir w direkt!
          props.onPushWeight(storedWorkout);         
        }

        //setQueryWTPIData(queryWTPIData + 1);
        
        //Aktualisierung nach oben pushen
        //props.onPushWeight(queryWTPIData);     
        
      })
      .catch(error => {console.log(error);  throw(error);} );
  }


  return (<>
          <hr />
          <div className="workoutPumpForm">
            {displayMode =='TP' ?
            
            <WorkoutTPIComponent training={currentTraining} 
                                 plan={currentPlanIdToRetrieve.id}
                                 queryWTPIData={queryWTPIData}
                                 addedWorkout={newWorkout}
                                 onChangeWTPI={changeWTPIHandler}/>
            : 
            <WorkoutSelectComponent onExerciseChange={changeExerciseHandler}/>
            }
            <RepetitionSelector selectedRep={selectedRep} componentName={props.componentName} onChange={changeRepetitionHandler}/>
            <WeightInput selectedWeight={selectedWeight} onPushWeight={pushWeightHandler} onChangeWeight={changeWeightHandler} />
            </div>
          <hr />
          </>);
}

export default WorkoutPumpForm;