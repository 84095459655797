import {useEffect, useState, useRef} from 'react';
import React from 'react';
import classes from './WorkoutHistoryComponent.module.css';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import {format,  formatDistance} from 'date-fns';
import LoadingSpinner from './LoadingSpinner';
import DeleteButton from './DeleteButton';

const WorkoutHistoryComponent = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [workoutHistory, setWorkoutHistory] = useState([]);



    const handleDeleteWorkout = (id) => {
      // Hier kannst du die Logik zum Löschen des Datensatzes implementieren
      console.log(`Datensatz mit ID ${id} wird gelöscht...`);
      fetch(
        process.env.REACT_APP_API_HOST+'/workout/'+id,
        {
          method: 'DELETE',
          headers: {'Authorization': 'Basic '+btoa(process.env.REACT_APP_BASIC_AUTH),
                    'Access-Control-Request-Headers': 'Authorization',
                     //'Content-Type': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                   }
        })
        .then((response) => {
             
          if (!response.ok) {
            throw(response.status + ' Fehler beim Löschen des Workouts.'); 
          }          
          return response.json();
        })
        .then((data) => {
  
          setError(false);
          console.log('Return of workout delete: ' + data.message);
          console.log('Return of workout delete: ' + data.object_id);
          
          const tmpWorkoutHist = workoutHistory.filter(item => item.id !== parseInt(data.object_id));
          
          setWorkoutHistory(tmpWorkoutHist);
        })
        .catch(error => {
          setError(error);
          setIsLoading(false);
        } );  

    };

    useEffect(() => {
        console.log("ReQuery History newWorkout :"+props.newWorkout.id);

        if(props.newWorkout.id == null)
        {
          setIsLoading(true);
          fetch(
            process.env.REACT_APP_API_HOST+"/workouthistory/"+props.training,
            {
              method: 'GET',
              headers: {'Authorization': 'Basic '+btoa(process.env.REACT_APP_BASIC_AUTH),
                        'Access-Control-Request-Headers': 'Authorization',
                        //'Content-Type': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                      }
            })
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              const workoutHistory = [];
              setWorkoutHistory(workoutHistory);
      
              for (const key in data) {
                  const tmpWH = {
                    id: key,
                    ...data[key]
                  };

                  workoutHistory.push(tmpWH);
                }
                setWorkoutHistory(workoutHistory);  
                setIsLoading(false);    
                console.log("lese history neu aus " + workoutHistory.length); 
            })
            .catch(error => {console.log(error);  throw(error);} );
        }
        else{
          // neues Workout hinzufügen
          setWorkoutHistory(workoutHistory => [props.newWorkout, ...workoutHistory]);
        }
      }, [props.newWorkout]);
    
      if (isLoading) {
        return (<LoadingSpinner />);
      }

    return (<section>
            {error && <div>{error}</div>}
            <div
            aria-live="polite"
            aria-atomic="true"
            className=" position-relative"
            style={{ minHeight: '400px', overflowY: 'auto', overflowX: 'hidden' }}>
                <ToastContainer className="p-3" position="top-start" style={{ zIndex: 1}}>
                {workoutHistory.map((history,index) => {return <Toast key={index} style={{width:600}}>
                                                            <Toast.Header closeButton={false} >
                                                                            <DeleteButton id={history.id} onDelete={handleDeleteWorkout} caption="x"/>
                                                                            <small>&nbsp;</small>
                                                                            <strong className="me-auto">{history.name}</strong>
                                                                            <small>{formatDistance(new Date(history.date), new Date(), { addSuffix: false })}</small>
                                                            </Toast.Header>
                                                            <Toast.Body>
                                                              <strong>{history.repetition} x {history.weight} kg - {history.category}</strong>
                                                              <small>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</small>
                                                              <strong>1RM: {Math.round(history.weight*(1+history.repetition/30) * 100) / 100} kg</strong>
                                                              <small>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</small>
                                                              <strong>[{history.id}]</strong>
                                                            </Toast.Body>                                                            
                                                            </Toast>})} 
                </ToastContainer>
            </div></section>)

}
  
export default WorkoutHistoryComponent;